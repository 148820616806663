import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const resources = {
  en: {
    translation: {
      welcome: 'Good morning.',
      info: 'Information',
      symp: 'Symptoms',
      safe: 'Safety rules',
      active: 'Status number',
      cases: 'Cases',
      recover: 'Recover',
      death: 'Deaths',
      vaccine: 'Vaccine',
      vaccineCount: 'Vaccinations',
      vaccineRate: 'Vaccine rate',
      vaccineComplete: 'completed',
      status: 'Status',
      continent: 'Continent Chart',
      history: 'Cumulative History',
      btncase: 'Vaccine & Cases & Recoverery',
      btnrate: 'Rate',
      btnAbout: 'About COVID-19',
      btnMask: 'Going to buy a mask',
      koreaStatus: 'COVID-19 & Vaccine Status in Korea',
      rate: 'Rate',
      rateFatal: 'Fatality Rate',
      rateRecover: 'Recovery Rate',
      affected: 'affected Countries',
      per1m: 'Per 1 Million',
      country: 'country',
      confirmation: 'Confirmation',
      recovered: 'Recovered',
      distance: 'Social Distancing',
    },
  },
  ko: {
    translation: {
      welcome: '좋은 아침 입니다.',
      info: '정보',
      symp: '증상',
      safe: '안전 수칙',
      active: '발생 수',
      cases: '확진',
      recover: '완치',
      death: '사망',
      vaccine: '백신 접종',
      vaccineCount: '백신 접종횟수',
      vaccineRate: '접종률',
      vaccineComplete: '접종완료',
      status: '발생 현황',
      continent: '대륙별 현황',
      history: '누적 현황',
      btncase: '백신 & 확진자 & 완치자수',
      btnrate: '치명률 & 완치율',
      btnAbout: '코로나 바이러스란?',
      btnMask: '방역 마스크 사러가기',
      koreaStatus: '한국 코로나 & 백신 현황',
      rate: '치명률 & 완치율',
      rateFatal: '치명률',
      rateRecover: '완치율',
      affected: '발생 국가',
      per1m: '100만명 당',
      country: '국가',
      confirmation: '누적 확진',
      recovered: '누적 완치',
      distance: '사회적 거리두기',
    },
  },
};

let includeKo = true;
includeKo = navigator.language.includes('ko');
// const includeKo = navigator.language.includes('ko');

i18n.use(initReactI18next).init({
  resources,
  lng: includeKo ? 'ko' : 'en',
});
export default i18n;
