import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import CheerCmpNewsCovid from '../CheerCmpNewsCovid';
import CheerCmpNewsCovidEn from '../CheerCmpNewsCovidEn';

import { useTranslation } from 'react-i18next';
import '../../i18n';
import './index.scss';
import ContInfo from './ContInfo';
import ContSymp from './ContSymp';
import ContSafe from './ContSafe';
import ContDistance from './ContDistance';

const About = ({ lang }) => {
  const { t, i18n } = useTranslation();

  const isKo = lang === 'ko';
  const [activeIdx, setActiveIdx] = useState(0);

  // const tabList = ['covid-19', 'Symptoms', 'Safety rules', 'News'];
  const tabList = ['COVID-19', t('distance'), t('symp'), t('info'), t('safe')];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleClick = (idx) => {
    setActiveIdx(idx);
    window.scrollTo(0, 0);
  };

  const adFunction = () => {
    const script = document.createElement('script');
    script.src = '//t1.daumcdn.net/kas/static/ba.min.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  };
  // 마운트시 애드핏 cdn 추가, 서브 이동시마다 업뎃
  useEffect(() => {
    adFunction();
  }, []);

  return (
    <>
      <section className="about">
        {/* <Link className="btn-link" to="/">
          Home
        </Link> */}
        <div className="tab-wrap">
          {tabList.map((tab, i) => (
            <button
              onClick={() => handleClick(i)}
              key={i}
              className={i === activeIdx ? 'on' : ''}
            >
              {tab}
            </button>
          ))}
        </div>

        {/* 컨텐츠 중간 1 */}
        <div className="ad-area">
          <ins
            class="kakao_ad_area"
            style={{ display: 'none' }}
            data-ad-unit="DAN-4NLJkzhmGGtN8ZLk"
            data-ad-width="320"
            data-ad-height="100"
          ></ins>
          <script
            type="text/javascript"
            src="//t1.daumcdn.net/kas/static/ba.min.js"
            async
          ></script>
        </div>

        {activeIdx === 0 && (
          <>
            <article className="col-box1">
              <h3>
                <span>COVID-19</span>
              </h3>
              {isKo ? (
                <p>
                  COVID-19는 SARS-CoV-2라는 신종 코로나 바이러스에 의해 발생하는
                  질병입니다.
                  <br />
                  WHO는 2019년 12월 31일 중국 우한에서 발생한 '바이러스 성
                  폐렴'사례 집단보고에 이어 신종 ​​바이러스에 대해 처음 보고
                  되었습니다.
                </p>
              ) : (
                <p>
                  COVID-19 is the disease caused by a new coronavirus called
                  SARS-CoV-2. WHO first learned of this new virus on 31 December
                  2019, following a report of a cluster of cases of ‘viral
                  pneumonia’ in Wuhan, People’s Republic of China.
                </p>
              )}
            </article>
            {isKo ? (
              <>
                <article>
                  <h4>감염재생산지수</h4>
                  <ul>
                    <li>
                      확진자 1명이 다른 사람 몇 명을 감염시키는지 보여주는
                      지표입니다.
                    </li>
                    <li>
                      이 수치가 1 이상이면 유행 확산을, 1 미만이면 유행 억제를
                      각각 뜻합니다.
                    </li>
                    <li>
                      코로나19 3차 유행 절정기인 지난달 1을 크게 웃돌았지만 새해
                      들어 확진자가 감소하면서 0.8대까지 떨어졌습니다.
                    </li>
                  </ul>
                </article>
                <article>
                  <h4>
                    COVID-19로부터 자신과 다른 사람을 안전하게 지키기 위해 해야
                    ​​할 일
                  </h4>
                  <ul>
                    <li>
                      기침, 재채기 또는 말을 할 때 감염 위험을 줄이기 위해
                      자신과 다른 사람 사이에 최소 1미터 거리를 유지하세요.
                      실내에서 자신과 다른 사람 사이의 거리를 더 멀리
                      유지하세요. 멀리 떨어질수록 좋습니다.
                    </li>
                    <li>
                      다른 사람들과 함께할때 마스크를 착용하는 것을
                      생활화하세요.
                    </li>
                  </ul>
                </article>
              </>
            ) : (
              <article>
                <h4>
                  What to do to keep yourself and others safe from COVID-19
                </h4>
                <ul>
                  <li>
                    Maintain at least a 1-metre distance between yourself and
                    others to reduce your risk of infection when they cough,
                    sneeze or speak. Maintain an even greater distance between
                    yourself and others when indoors. The further away, the
                    better.
                  </li>
                  <li>
                    Make wearing a mask a normal part of being around other
                    people. The appropriate u
                  </li>
                </ul>
              </article>
            )}

            {isKo ? (
              <article>
                <h4>코로나 바이러스 분류 및 특성</h4>

                <div>
                  <dl>
                    <dt>요약</dt>
                    <dd>
                      코로나 바이이러스(CoV)는 사람과 다양한 동물에 감염될 수
                      있는 바이러스로서 유전자 크기 27~32kb의 RNA 바이러스
                    </dd>
                  </dl>
                  <dl>
                    <dt>분류</dt>
                    <dd>
                      <p>
                        코로나 바이러스과에는 4개의 속(알파, 베타, 감마, 델타)이
                        있음
                      </p>
                      <ul>
                        <li>알파 · 베타 : 사람과 동물에게 감염</li>
                        <li>감마 · 델타 : 동물에게 감염</li>
                      </ul>
                    </dd>
                  </dl>
                  <dl>
                    <dt>모양</dt>
                    <dd>
                      <p>
                        형태는 코로나 바이러스의 명명과 같이 전자현미경 관찰시
                        구형
                      </p>
                      <ul>
                        <li>외부 스파이크 단백질이 특징 인 크라운 모양</li>
                      </ul>
                    </dd>
                  </dl>
                  <dl>
                    <dt>사람 전염성 코로나 바이러스</dt>
                    <dd>
                      <p>
                        현재까지 알려진 사람 전염성 코로나 바이러스에는 6가지
                        유형이 있습니다.
                      </p>
                      <ul>
                        <li>감기를 유발하는 유형 (229E, OC43, NL63, HKU1)</li>
                        <li>
                          중증폐렴을 일으킬 수 있는 유형(SARS-CoV, MERS-CoV)
                        </li>
                      </ul>
                    </dd>
                  </dl>
                </div>
              </article>
            ) : (
              <article>
                <h4>Coronavirus classification and characteristics</h4>

                <div>
                  <dl>
                    <dt>summary</dt>
                    <dd>
                      Corona virus (CoV) is a virus that can infect humans and
                      various animals, and is an RNA virus with a gene size of
                      27 to 32 kb.
                    </dd>
                  </dl>
                  <dl>
                    <dt>Classification</dt>
                    <dd>
                      <p>
                        There are 4 genera (alpha, beta, gamma, delta) in the
                        coronavirus family.
                      </p>
                      <ul>
                        <li>Alpha · Beta: Infects humans and animals</li>
                        <li>Gamma/Delta: Infects animals</li>
                      </ul>
                    </dd>
                  </dl>
                  <dl>
                    <dt>shape</dt>
                    <dd>
                      <p>
                        The shape is spherical when observed with an electron
                        microscope as the name of the coronavirus.
                      </p>
                      <ul>
                        <li>
                          Crown shape characterized by external spike protein
                        </li>
                      </ul>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Human infectious coronavirus</dt>
                    <dd>
                      <p>
                        There are 6 types of human infectious coronaviruses
                        known to date.
                      </p>
                      <ul>
                        <li>Types that cause cold (229E, OC43, NL63, HKU1)</li>
                        <li>
                          Types that can cause severe pneumonia (SARS-CoV,
                          MERS-CoV)
                        </li>
                      </ul>
                    </dd>
                  </dl>
                </div>
              </article>
            )}
          </>
        )}
        {/* 거리두기 */}
        {activeIdx === 1 && <ContDistance isKo={isKo} />}

        {/* 증상 */}
        {activeIdx === 2 && <ContSymp isKo={isKo} />}
        {/* 정보 */}
        {activeIdx === 3 && <ContInfo isKo={isKo} />}
        {/* 안전 수칙 */}
        {activeIdx === 4 && <ContSafe isKo={isKo} />}

        {activeIdx === 5 && (
          <>
            <div>
              {/* <CheerCmpNewsCovidEn /> */}
              <CheerCmpNewsCovid />
            </div>
          </>
        )}

        {/* 컨텐츠 하단 */}
        <div className="ad-area">
          <ins
            class="kakao_ad_area"
            style={{ display: 'none' }}
            data-ad-unit="DAN-0fwegtkm35HAb0p6"
            data-ad-width="320"
            data-ad-height="100"
          ></ins>
          <script
            type="text/javascript"
            src="//t1.daumcdn.net/kas/static/ba.min.js"
            async
          ></script>
        </div>

        <p className="cont-copy"> 내용 출처 : MOHW, WHO</p>
      </section>
    </>
  );
};

export default About;
