import React, { useState, useEffect } from 'react';
import { Circle, Popup } from 'react-leaflet';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import '../i18n';

import './index.scss';
/** 숫자 대비 어느정도 값이 있어야 원형이 보임 */
const casesTypeColors = {
  cases: {
    hex: '#ddbf17',
    multiplier: 600,
  },
  recovered: {
    hex: '#019ccb',
    multiplier: 600,
  },
  deaths: {
    hex: '#cf4218',
    multiplier: 1200,
  },
  vaccine: {
    hex: '#18cf64',
    multiplier: 1200,
  },
  select: {
    hex: '#d6450c',
    multiplier: 600,
  },
};

export const ShowDataOnMap = (
  data,
  selectCountry,
  vaccine,
  casesType = 'cases'
) => {
  // console.log(selectCountry, 'select');

  /**
   * 백신 country만 있음
   * data꺼에서 country 이름으로 필터링 해서 iso 받아오기
   */
  const [vaccineCountry, setVaccineCountry] = useState(null);

  useEffect(() => {
    if (!vaccine || !data) return;
    let arr = vaccine;
    arr.map(
      (vv, i) => (vv.loca = data.filter((cn, i) => cn.country === vv.country))
    );
    setVaccineCountry(arr);

    // console.log(arr[0].loca[0].countryInfo);
  }, [vaccine, data]);

  const { t, i18n } = useTranslation();

  return vaccineCountry?.map((country, index) => {
    // const isSelect = country.loca[0].countryInfo.iso2 === selectCountry;
    const isSelect = false;
    // console.log(isSelect);

    console.log(country);
    return (
      <Circle
        center={[
          country.loca[0].countryInfo.lat,
          country.loca[0].countryInfo.long,
        ]}
        // fillOpacity={0.1}
        fillOpacity={
          isSelect
            ? 0.6
            : (0.11 *
                Math.sqrt(country[casesType]) *
                casesTypeColors[casesType].multiplier) /
              500000
        }
        color={
          isSelect
            ? casesTypeColors['select'].hex
            : casesTypeColors[casesType].hex
        }
        fillColor={
          isSelect
            ? casesTypeColors['select'].hex
            : casesTypeColors[casesType].hex
        }
        weight={1.2}
        radius={
          Math.sqrt(country[casesType]) * casesTypeColors[casesType].multiplier
        }
        key={index}
      ></Circle>
    );
  });
};

export const prettyPrintStat = (stat) => {
  // return stat ? `+${numeral(stat).format('0,0a')}` : '+0';
  return stat ? `+${Number(stat).toLocaleString()}` : '+0';
};
