import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n';

import './index.scss';

const StateDesc = ({ countryInfo, country, dataContinent }) => {
  // 다국어
  const { t, i18n } = useTranslation();
  return (
    <>
      <section className="data-description">
        <article className="col-box1">
          <h3>
            <strong>{country ?? '전 세계'}</strong>
            <span>{t('status')}</span>
          </h3>

          <div className="cont">
            <div>
              <h4> {t('active')}</h4>

              {countryInfo.cases > 0 && (
                <p>
                  <span>{t('cases')}</span>
                  <strong> {countryInfo.cases.toLocaleString()}</strong>
                </p>
              )}
              {countryInfo.recovered > 0 && (
                <p>
                  <span>{t('recover')}</span>
                  <strong> {countryInfo.recovered.toLocaleString()}</strong>
                </p>
              )}
              {countryInfo.deaths > 0 && (
                <p>
                  <span>{t('death')}</span>
                  <strong> {countryInfo.deaths.toLocaleString()}</strong>
                </p>
              )}
            </div>
            <div>
              <h4> {t('per1m')}</h4>
              <p>
                <span>{t('cases')} </span>
                <strong>
                  {Number(countryInfo.casesPerOneMillion).toLocaleString()}
                </strong>
              </p>
              <p>
                <span> {t('recover')}</span>
                <strong>
                  {
                    Number(countryInfo.recoveredPerOneMillion)
                      .toLocaleString()
                      .split('.')[0]
                  }
                </strong>
              </p>
              <p>
                <span>{t('death')} </span>
                <strong>
                  {Number(countryInfo.deathsPerOneMillion)
                    .toFixed(0)
                    .toLocaleString()}
                </strong>
              </p>
            </div>
            <div>
              <h4> {t('rate')}</h4>
              <p>
                <span>{t('rateFatal')} </span>
                <strong>
                  {(
                    (Number(countryInfo.deaths) / Number(countryInfo.cases)) *
                    100
                  ).toFixed(2)}
                  {` `}%
                </strong>
              </p>
              <p>
                <span> {t('rateRecover')} </span>
                <strong>
                  {(
                    (Number(countryInfo.recovered) /
                      Number(countryInfo.cases)) *
                    100
                  ).toFixed(2)}
                  {` `}%
                </strong>
              </p>
              {countryInfo.affectedCountries > 0 && (
                <p>
                  <span>{t('affected')}</span>
                  <strong>{countryInfo.affectedCountries}</strong>
                </p>
              )}
            </div>
          </div>
        </article>
        {/* {dataContinent && (
          <article className="col-box2">
            <h3>contient</h3>
            {dataContinent.map(
              (data, i) =>
                data.name && (
                  <p>
                    {data.name} : {data.value.toLocaleString()}
                  </p>
                )
            )}
          </article>
        )} */}
      </section>
    </>
  );
};

export default StateDesc;
