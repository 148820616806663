import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { RiKakaoTalkFill } from 'react-icons/ri';
import { HiLink } from 'react-icons/hi';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './index.scss';

const ShareKakao = ({}) => {
  useEffect(() => {
    // console.log('kakao is..', window, window.Kakao);
    if (window.Kakao) {
      // console.log('is kakao');
      const kakao = window.Kakao;
      // 중복 initialization 방지
      if (!kakao.isInitialized()) {
        // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
        kakao.init('bd3a8957e26fda337a932ebe4a7b5b67');
      }
      kakao.Link.createDefaultButton({
        // Render 부분 id=kakao-link-btn 을 찾아 그부분에 렌더링을 합니다
        container: '#btn-share-kakao',
        objectType: 'feed',
        content: {
          title: '🌍Corona Graph 코로나 그래프',
          description: `covid-19 world dashboard graph, 전세계 코로나 그래프, 나라별 코로나 현황`,
          imageUrl: `${`https://coronagraph.vercel.app/og-square.png`}`, // i.e. process.env.FETCH_URL + '/logo.png'
          link: {
            mobileWebUrl: 'https://coronagraph.vercel.app/',
            webUrl: 'https://coronagraph.vercel.app/',
          },
        },
        buttons: [
          {
            title: '전세계 코로나 현황 보기',
            link: {
              mobileWebUrl: 'https://coronagraph.vercel.app/',
            },
          },
        ],
      });
    }
  }, [window.Kakao]);

  return (
    <>
      <CopyToClipboard
        text="https://coronagraph.vercel.app/"
        onCopy={() => alert('copied')}
      >
        <button aria-label="링크 복사하기">
          <HiLink />
        </button>
      </CopyToClipboard>
      <button id="btn-share-kakao" aria-label="카카오톡 공유하기">
        <RiKakaoTalkFill />
      </button>
    </>
  );
};

export default ShareKakao;
