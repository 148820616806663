import React, { useEffect, useState } from 'react';

const ContSafe = ({ isKo }) => {
  return (
    <>
      {/* 안전 수칙 */}
      {isKo ? (
        <>
          <article>
            <h3>
              <span>마스크 착용 방법</span>
            </h3>
            <ul>
              <li>
                마스크를 착용하기 전, 벗기기 전과 후에, 그리고 마스크를 만진
                후에는 언제든지 손을 씻으세요.
              </li>
              <li>코, 입, 턱을 모두 덮으세요.</li>
              <li>
                마스크를 벗을 때는 깨끗한 비닐 봉지에 보관하고 천 마스크라면
                매일 씻거나 의료용 마스크를 쓰레기통에 버리세요.
              </li>
              <li>밸브형 마스크를 사용하지 마세요.</li>
            </ul>
          </article>
          <article>
            <h4>환경을 더 안전하게 만드는 방법</h4>
            <ul>
              <li>
                폐쇄되거나 혼잡하거나 밀접한 접촉이있는 공간을 피하세요.
                <ul>
                  <li>
                    사람들이 모이는 식당, 합창단 관행, 피트니스 수업, 나이트
                    클럽, 사무실 및 예배 장소에서 발병이보고되었으며, 종종
                    사람들이 큰 소리로 말하고, 소리를 지르고, 숨을 크게 쉬거나,
                    노래하는 혼잡 한 실내 환경에서 발생했습니다.
                  </li>
                  <li>
                    COVID-19에 걸릴 위험은 감염된 사람들이 가까운 곳에서 오랜
                    시간을 함께 보내는 혼잡하고 환기가 잘되지 않는 공간에서 더
                    높습니다. 이러한 환경은 바이러스가 호흡기 비말이나
                    에어로졸에 의해보다 효율적으로 확산되는 것처럼 보이기 때문에
                    예방 조치를 취하는 것이 훨씬 더 중요합니다.
                  </li>
                </ul>
              </li>
              <li>
                혼잡하거나 실내 설정을 피하되, 할 수없는 경우 예방 조치를
                취하세요.
                <ul>
                  <li>창을 엽니 다. 실내에서는 '자연 환기'의 양을 늘립니다.</li>
                  <li>마스크 착용</li>
                </ul>
              </li>
            </ul>
          </article>
          <article>
            <h4>좋은 위생의 기본을 잊지 마세요</h4>
            <ul>
              <li>
                알코올 성분의 손 문지르기로 손을 정기적으로 철저히 닦거나 비누와
                물로 손을 씻으세요. 이것은 당신의 손에있을 수 있는 바이러스를
                포함한 세균을 제거합니다.
              </li>
              <li>
                눈, 코, 입을 만지지 마세요. 손은 많은 표면을 만지고 바이러스를
                포착 할 수 있습니다. 일단 오염되면 손이 바이러스를 눈, 코 또는
                입으로 옮길 수 있습니다. 거기에서 바이러스가 몸에 들어가 감염 될
                수 있습니다.
              </li>
              <li>
                기침이나 재채기를 할 때 구부러진 팔꿈치 나 티슈로 입과 코를
                가리세요. 그런 다음 사용한 휴지는 즉시 밀폐 된 빈에 버리고 손을
                씻으세요. 좋은 '호흡기 위생'을 준수함으로써 감기, 독감 및
                COVID-19를 유발하는 바이러스로부터 주변 사람들을 보호합니다.
              </li>
              <li>
                특히 문 손잡이, 수도꼭지 및 전화기 화면과 같이 정기적으로 만지는
                표면을 자주 청소하고 소독하세요.
              </li>
            </ul>
          </article>
          <article>
            <h4>몸이 안 좋을 때 해야 할 일</h4>
            <ul>
              <li>
                COVID-19의 모든 증상에 대해 알아보세요. COVID-19의 가장 흔한
                증상은 발열, 마른 기침 및 피로입니다. 덜 흔하고 일부 환자에게
                영향을 미칠 수있는 다른 증상으로는 미각 또는 후각 상실, 통증 및
                통증, 두통, 인후염, 코 막힘, 적목 현상, 설사 또는 피부 발진이
                있습니다.
              </li>
              <li>
                기침, 두통, 가벼운 열과 같은 경미한 증상이 있어도 회복 될 때까지
                집에 머물러자가 격리하세요. 의료 제공자 나 핫라인에 전화하여
                조언을 구하세요. 누군가가 당신에게 물품을 가져 오도록하세요.
                집을 떠나야하거나 근처에 누군가가있는 경우 다른 사람을
                감염시키지 않도록 의료용 마스크를 착용하세요.
              </li>
              <li>
                열, 기침, 호흡 곤란이있는 경우 즉시 치료를 받으세요. 가능하면
                먼저 전화로 전화하고 지역 보건 당국의 지시를 따르세요.
              </li>
              <li>
                신뢰할 수있는 출처의 최신 정보를 최신 상태로 유지하세요. 지역 및
                국가 당국과 공중 보건 부서는 귀하 지역의 사람들이 자신을
                보호하기 위해 무엇을 해야 하는지에 대해 조언을 제공하는 것이
                가장 좋습니다.
              </li>
            </ul>
          </article>
        </>
      ) : (
        <>
          <article>
            <h3>
              <span>How to wear a mask</span>
            </h3>
            <ul>
              <li>
                Clean your hands before you put your mask on, as well as before
                and after you take it off, and after you touch it at any time.
              </li>
              <li> Make sure it covers both your nose, mouth and chin.</li>
              <li>
                When you take off a mask, store it in a clean plastic bag, and
                every day either wash it if it’s a fabric mask, or dispose of a
                medical mask in a trash bin.
              </li>
              <li> Don’t use masks with valves.</li>
            </ul>
          </article>
          <article>
            <h4>How to make your environment safer</h4>
            <ul>
              <li>
                Avoid the 3Cs: spaces that are closed, crowded or involve close
                contact.
                <ul>
                  <li>
                    Outbreaks have been reported in restaurants, choir
                    practices, fitness classes, nightclubs, offices and places
                    of worship where people have gathered, often in crowded
                    indoor settings where they talk loudly, shout, breathe
                    heavily or sing.
                  </li>
                  <li>
                    The risks of getting COVID-19 are higher in crowded and
                    inadequately ventilated spaces where infected people spend
                    long periods of time together in close proximity. These
                    environments are where the virus appears to spreads by
                    respiratory droplets or aerosols more efficiently, so taking
                    precautions is even more important.
                  </li>
                </ul>
              </li>
              <li>
                Avoid crowded or indoor settings but if you can’t, then take
                precautions
                <ul>
                  <li>
                    Open a window. Increase the amount of ‘natural ventilation’
                    when indoors.
                  </li>
                  <li>
                    WHO has published Q&amp;As on ventilation and air
                    conditioning for both the general public and people who
                    manage public spaces and buildings.
                  </li>
                  <li>Wear a mask</li>
                </ul>
              </li>
            </ul>
          </article>
          <article>
            <h4>Don’t forget the basics of good hygiene</h4>
            <ul>
              <li>
                Regularly and thoroughly clean your hands with an alcohol-based
                hand rub or wash them with soap and water. This eliminates germs
                including viruses that may be on your hands.
              </li>
              <li>
                Avoid touching your eyes, nose and mouth. Hands touch many
                surfaces and can pick up viruses. Once contaminated, hands can
                transfer the virus to your eyes, nose or mouth. From there, the
                virus can enter your body and infect you.
              </li>
              <li>
                Cover your mouth and nose with your bent elbow or tissue when
                you cough or sneeze. Then dispose of the used tissue immediately
                into a closed bin and wash your hands. By following good
                ‘respiratory hygiene’, you protect the people around you from
                viruses, which cause colds, flu and COVID-19.
              </li>
              <li>
                Clean and disinfect surfaces frequently especially those which
                are regularly touched, such as door handles, faucets and phone
                screens.
              </li>
            </ul>
          </article>
          <article>
            <h4>What to do if you feel unwell</h4>
            <ul>
              <li>
                Know the full range of symptoms of COVID-19. The most common
                symptoms of COVID-19 are fever, dry cough, and tiredness. Other
                symptoms that are less common and may affect some patients
                include loss of taste or smell, aches and pains, headache, sore
                throat, nasal congestion, red eyes, diarrhoea, or a skin rash.
              </li>
              <li>
                Stay home and self-isolate even if you have minor symptoms such
                as cough, headache, mild fever, until you recover. Call your
                health care provider or hotline for advice. Have someone bring
                you supplies. If you need to leave your house or have someone
                near you, wear a medical mask to avoid infecting others.
              </li>
              <li>
                If you have a fever, cough and difficulty breathing, seek
                medical attention immediately. Call by telephone first, if you
                can and follow the directions of your local health authority.
              </li>
              <li>
                Keep up to date on the latest information from trusted sources,
                such as WHO or your local and national health authorities. Local
                and national authorities and public health units are best placed
                to advise on what people in your area should be doing to protect
                themselves.
              </li>
            </ul>
          </article>
        </>
      )}
    </>
  );
};

export default ContSafe;
