import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { FiSave } from 'react-icons/fi';

import './index.scss';

const SaveImage = ({ countryInfo }) => {
  /** 이미지 저장 */
  const [imgUrl, setImgUrl] = useState('');
  const handleDrawImage = () => {
    const width = 300;
    const height = 200;
    const ratio = window.devicePixelRatio;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // style의 width, height 지정
    canvas.style.width = width + 'px';
    canvas.style.height = height + 'px';

    // attribute에 ratio 값을 곱한 width, height 지정
    canvas.width = width * ratio;
    canvas.height = height * ratio;

    // context scale 지정
    ctx.scale(ratio, ratio);

    const defaultXPos = 20;
    const xPos = 170;
    const yPos = 70;
    const yHeight = (n) => yPos + 30 + 20 * n;

    ctx.beginPath();
    // ctx.rect(20, 20, 150, 100);
    ctx.rect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = '#262938';
    ctx.fill();

    ctx.font = '22px sans-serif';
    ctx.fillStyle = 'white';
    ctx.fillText('🌍 COVID-19 World Cases', defaultXPos, 40);

    ctx.font = '13px sans-serif';
    ctx.fillStyle = 'white';
    ctx.fillText(format(new Date(), 'yyyy. MM. dd'), defaultXPos, yPos);
    ctx.font = '11px sans-serif';
    ctx.fillStyle = 'rgba(255,255,255,.5)';

    ctx.fillText(format(new Date(), 'EEEE pp'), defaultXPos, yPos + 17);

    ctx.font = '13px sans-serif';
    ctx.fillStyle = 'rgba(255,255,255,.7)';
    ctx.fillText('Cases', defaultXPos, yHeight(1));
    ctx.fillText('Recovered', defaultXPos, yHeight(2));
    ctx.fillText('Deaths', defaultXPos, yHeight(3));
    ctx.textAlign = 'right';
    ctx.fillStyle = 'rgba(255,255,255,1)';

    ctx.fillText(countryInfo.cases.toLocaleString(), xPos, yHeight(1));
    ctx.fillText(countryInfo.recovered.toLocaleString(), xPos, yHeight(2));
    ctx.fillText(countryInfo.deaths.toLocaleString(), xPos, yHeight(3));

    ctx.font = '10px sans-serif';
    ctx.fillStyle = 'rgba(255,255,255,.9)';

    ctx.fillText('Corona Graph', 290, 175);
    ctx.fillStyle = 'rgba(255,255,255,.4)';
    ctx.font = '9px sans-serif';

    ctx.fillText('coronagraph.vercel.app', 290, 190);

    const myimg = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream'); // here is the most important part because if you dont replace you will get a DOM 18 exception.

    setImgUrl(myimg);
    // console.log(myimg);
  };

  // 데이터 로드시 상황 그리기
  useEffect(() => {
    if (Object.values(countryInfo).length) {
      handleDrawImage();
    }
  }, [countryInfo]);

  // 이미지 저장
  const handleSaveImage = () => {
    const link = document.createElement('a');
    link.setAttribute('download', 'image.png');
    link.setAttribute('href', imgUrl);
    link.click(); //다운로드
    // link.remove();
  };

  return (
    <>
      {/* {imgUrl && <img src={imgUrl} alt="" />} */}
      <button className="save-image" onClick={handleSaveImage}>
        <FiSave />
      </button>
    </>
  );
};

export default SaveImage;
