/** @jsx jsx */
import { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from 'react-router-dom';
import { jsx, ThemeProvider, Box, Flex, Text, Card } from 'theme-ui';
import { useSpring, animated } from 'react-spring';
import 'leaflet/dist/leaflet.css';
import theme from './theme';
import axios from 'axios';

//Relative path imports
import { prettyPrintStat } from './helpers/index-백백';
import contryCode from './coountry.json';

import SVGIcon from './components/Icons/index';
import ListContainer from './components/ListContainer/index';
import WorldMap from './components/WorldMap/index';
import StateDesc from './components/StateDesc';
// import CovidPieNationChart from './components/CovidPieNationChart/index';
// import List from './components/List/index';
// import HistoryChart from './components/HistoryChart';

import { BiWorld, BiLineChart } from 'react-icons/bi';
import { GrInfo } from 'react-icons/gr';
import { HiOutlineMenuAlt2 } from 'react-icons/hi';
import { TiDelete } from 'react-icons/ti';

import './base.css';
import './index.scss';
import SaveImage from './components/SaveImage';
import ShareKakao from './components/ShareKakao';
import About from './components/About';
import { useTranslation } from 'react-i18next';
import './i18n'; // 다국어 지원 파일

function App() {
  // 다국어
  const { t, i18n } = useTranslation();
  const changelanguageToKo = () => i18n.changeLanguage('ko');
  const changelanguageToEn = () => i18n.changeLanguage('en');
  const [isKorea, setIsKorea] = useState(true);

  const handleKorea = () => {
    setIsKorea(true);
    changelanguageToKo();
  };

  const handleEnglish = () => {
    setIsKorea(false);
    changelanguageToEn();
  };

  let location = useLocation();

  // console.log(location.pathname);

  // lazyload component
  const CovidPieNationChart = lazy(() =>
    import('./components/CovidPieNationChart/index')
  );
  const Content = lazy(() => import('./components/Content/index'));
  // const WorldMap = lazy(() => import('./components/WorldMap/index'));
  // const StateDesc = lazy(() => import('./components/StateDesc/index'));
  const HistoryChart = lazy(() => import('./components/HistoryChart/index'));
  const List = lazy(() => import('./components/List/index'));

  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState('Worldwide');
  const [countryInfo, setCountryInfo] = useState({});
  const [mapCenter, setMapCenter] = useState({ lat: 34.80746, lng: -40.4796 });
  const [mapZoom, setMapZoom] = useState(2);
  const [mapCountries, setMapCountries] = useState([]);
  const [casesType, setCasesType] = useState('cases');

  // 검색초기리스트
  const [filterList, setFilterList] = useState(countries);
  const [filterListAfter, setFilterListAfter] = useState(countries);

  //Animation
  const AnimatedCard = animated(Card);
  const AnimatedText = animated(Text);
  const AnimatedSVGIcon = animated(SVGIcon);

  const [toggle, setToggle] = useState(true);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);
  const [toggle4, setToggle4] = useState(false);

  // const cardOffcolor = '#393d4c';
  const cardOffcolor = 'rgba(58, 63, 70, 0.85)';
  const cardOffText = '#adb1bb';

  const { x, color, fill, backgroundColor } = useSpring({
    from: { x: 1 },
    x: toggle && casesType === 'cases' ? 1 : 1,
    color: toggle && casesType === 'cases' ? '#faf9ea' : cardOffText,
    backgroundColor: toggle && casesType === 'cases' ? '#ceaf00' : cardOffcolor,
    fill: toggle && casesType === 'cases' ? '#faf9ea' : cardOffText,
  });

  const { x2, color2, fill2, backgroundColor2 } = useSpring({
    from: { x2: 1 },
    x2: toggle2 && casesType === 'recovered' ? 1 : 1,
    color2: toggle2 && casesType === 'recovered' ? '#e9f1fc' : cardOffText,
    backgroundColor2:
      toggle2 && casesType === 'recovered' ? '#019ccb' : cardOffcolor,
    fill2: toggle2 && casesType === 'recovered' ? '#e9f1fc' : cardOffText,
  });

  const { x3, color3, fill3, backgroundColor3 } = useSpring({
    from: { x3: 1 },
    x3: toggle3 && casesType === 'deaths' ? 1 : 1,
    color3: toggle3 && casesType === 'deaths' ? '#6b6e79' : cardOffText,
    backgroundColor3:
      toggle3 && casesType === 'deaths' ? '#14151b' : cardOffcolor,
    fill3: toggle3 && casesType === 'deaths' ? '#6b6e79' : cardOffText,
  });

  const { x4, color4, fill4, backgroundColor4 } = useSpring({
    from: { x4: 1 },
    x4: toggle4 && casesType === 'vaccine' ? 1 : 1,
    color3: toggle4 && casesType === 'vaccine' ? '#4fb381' : cardOffText,
    backgroundColor4:
      toggle4 && casesType === 'vaccine' ? '#14151b' : cardOffcolor,
    fill3: toggle4 && casesType === 'vaccine' ? '#4fb381' : cardOffText,
  });

  const onClickAnimateCard1 = () => {
    setToggle(!toggle);
    setToggle2(false);
    setToggle3(false);
    setToggle4(false);

    setCasesType('cases');
  };

  const onClickAnimateCard2 = () => {
    setToggle2(!toggle2);
    setToggle(false);
    setToggle3(false);
    setToggle4(false);

    setCasesType('recovered');
    // console.log(casesType);
  };

  const onClickAnimateCard3 = () => {
    setToggle3(!toggle3);
    setToggle(false);
    setToggle2(false);
    setToggle4(false);

    setCasesType('deaths');
  };
  const onClickAnimateCard4 = () => {
    setToggle4(!toggle4);
    setToggle(false);
    setToggle2(false);
    setToggle3(false);

    setCasesType('vaccine');
  };

  //General
  useEffect(() => {
    fetch('https://disease.sh/v3/covid-19/all')
      .then((response) => response.json())
      .then((data) => {
        setCountryInfo(data);
        // console.log(data);
      });
  }, []);

  const [dataContinent, setDataContinent] = useState('');
  const [korList, setKorList] = useState('');
  const setFilterKoArr = () => {
    const filteredData = [];
    countries.map((country) => {
      const filterDatas = Object.values(contryCode).filter(
        (data, i) => data['2digitCode'] == country.value
      )[0];
      return filteredData.push({
        value: country.value,
        name: country.name,
        krname: filterDatas !== undefined ? filterDatas.CountryNameKR : '',
      });
    });

    setKorList(filteredData);
    setFilterList(filteredData);
    setFilterListAfter(filteredData);
  };
  useEffect(() => {
    setFilterKoArr();
  }, [countries]);

  useEffect(() => {
    const getCountriesData = async () => {
      await fetch('https://disease.sh/v3/covid-19/countries')
        .then((response) => response.json())
        .then((data) => {
          const countries = data.map((country) => {
            return {
              name: country.country,
              value: country.countryInfo.iso2,
            };
          });
          setCountries(countries);
          setMapCountries(data);

          // 대륙별 정렬
          const groupBy = (arr, property) => {
            return arr.reduce((acc, cur) => {
              acc[cur[property]] = [...(acc[cur[property]] || []), cur];
              return acc;
            }, {});
          };
          const groupNation = groupBy(data, 'continent');
          // console.log(groupNation);

          const sumData = Object.values(groupNation).map((data) => {
            return {
              name: data[0].continent,
              value: data.reduce((a, b) => a + b.cases, 0),
            };
          });

          // 미국 북아메 남아메 합계
          const filterContry = sumData.filter(
            (a, b) => !a.name.includes('America')
          );

          const americaSum = sumData
            .filter((a, b) => a.name.includes('America'))
            .reduce((a, b) => a.value + b.value);

          filterContry.push({ name: 'America', value: americaSum });

          const sortObj = filterContry.sort((a, b) => b.value - a.value);
          // console.log(sortObj);
          setDataContinent(sortObj);
        });
    };
    getCountriesData();
  }, []);

  /**
   * 백신
   */
  const [vaccine, setVaccine] = useState(null);

  const getVaccineData = async () => {
    await fetch('https://disease.sh/v3/covid-19/vaccine/coverage/countries')
      .then((response) => response.json())
      .then((data) => {
        // 최근날짜 latest
        data.map(
          (v, i) => (v.latest = Object.entries(v.timeline).reverse()[0][1])
        );

        data.sort((a, b) => b.latest - a.latest);
        // console.log('vvv', data);
        setVaccine(data);
      });
  };
  useEffect(() => {
    getVaccineData();
  }, []);

  // 백신 접종률 데이터
  const [vaccineData, setVaccineData] = useState('');

  const MASTER_DATA_URL =
    'https://raw.githubusercontent.com/owid/covid-19-data/master/public/data/vaccinations/vaccinations.csv';

  async function GetAllData() {
    var alldata = [];
    var lastadata = [];
    const { data } = await axios.get(MASTER_DATA_URL);
    let lines = data.split('\n');
    for (let i = 1; i < lines.length; i++) {
      alldata.push(lines[i].toString().split(','));
    }
    for (let i = 0; i < alldata.length - 1; i++) {
      if (alldata[i][1] != alldata[i + 1][1]) {
        lastadata.push(alldata[i].toString());
      }
    }
    // console.log(lastadata);
    const re = lastadata.map((data, i) => data.split(','));
    setVaccineData(re);
    // console.log(re);
  }

  useEffect(() => {
    GetAllData();
  }, []);

  const onCountrySelect = async (code) => {
    const countryCode = code;

    const url =
      countryCode === 'WorldWide'
        ? 'https://disease.sh/v3/covid-19/all'
        : `https://disease.sh/v3/covid-19/countries/${countryCode}`;

    await fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setCountry(countryCode);
        setCountryInfo(data);

        if (
          data.countryInfo &&
          setMapCenter([data.countryInfo.lat, data.countryInfo.long])
        )
          setMapZoom(4);
        // console.log('search code', data.countryInfo.lat, data.countryInfo.long);
      });
  };

  /** 기간 발생 추이 history */
  const [historyData, setHistoryData] = useState('');
  const getHistory = async () => {
    const url = 'https://disease.sh/v3/covid-19/historical/all';

    await fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // console.log(Object.values(data), 'history data');
        setHistoryData(Object.values(data));
      });
  };
  useEffect(() => {
    getHistory();
  }, []);

  // console.log(Object.values(contryCode), countries);

  /**
   * 검색
   */
  const [iptVal, setIptVal] = useState('');

  const handleInput = useCallback(
    (value) => {
      setIptVal(value);

      if (value === '') {
        setFilterKoArr(); //초기화
      } else {
        handleFilter(value);
      }
    },
    [iptVal]
  );

  const handleFilter = useCallback(
    (value) => {
      const filterWordArr = filterList.filter(
        (data) =>
          // (data.name && data.name.toLowerCase().includes(value)) ||
          // 한글자 입력후 받침 입력시에도 보이게.. 한글자라도 포함하면 보이게 할경우
          // (data.krname && value.split('').some((el)=> data.krname.includes(el) )) ||
          (data.krname && data.krname.toLowerCase().includes(value)) ||
          (data.name && data.name.toLowerCase().includes(value))
      );
      setFilterListAfter(filterWordArr);
    },
    [filterList]
  );

  const handleDelete = () => {
    setIptVal('');
    setFilterKoArr(); //초기화
  };

  /** 만 억 조 경 */
  function numberFormat(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  function numberToKorean(number) {
    var inputNumber = number < 0 ? false : number;
    var unitWords = ['', '만 ', '억 ', '조 ', '경 '];
    var splitUnit = 10000;
    var splitCount = unitWords.length;
    var resultArray = [];
    var resultString = '';

    for (var i = 0; i < splitCount; i++) {
      var unitResult =
        (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
      unitResult = Math.floor(unitResult);
      if (unitResult > 0) {
        resultArray[i] = unitResult;
      }
    }

    for (var i = 0; i < resultArray.length; i++) {
      if (!resultArray[i]) continue;
      resultString =
        String(numberFormat(resultArray[i])) + unitWords[i] + resultString;
    }

    return resultString;
  }

  /** 카톡공유 */
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://developers.kakao.com/sdk/js/kakao.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <div className="link-box">
        {location.pathname === '/about' ? (
          <Link to="/" aria-label="home">
            <BiLineChart />
          </Link>
        ) : (
          <Link to="/about" aria-label="covid-19 about">
            {/* <GrInfo /> */}
            <HiOutlineMenuAlt2 />
          </Link>
        )}
      </div>

      {/* 다국어 설정 버튼 */}
      <div className="btn-lang">
        <button
          className={i18n.language === 'ko' ? 'on' : ''}
          onClick={handleKorea}
        >
          Korean
        </button>
        <button
          className={i18n.language === 'en' ? 'on' : ''}
          onClick={handleEnglish}
        >
          English
        </button>
      </div>

      <Switch>
        <Route path="/about">
          <About lang={i18n.language} />
        </Route>
        <Route path="/">
          <ThemeProvider theme={theme}>
            <Box>
              <div className="covid-header">
                <Flex>
                  <Box
                    sx={{
                      flex: 1,
                      width: '100%',
                      maxWidth: '200px',
                      svg: {
                        display: 'none',
                      },
                    }}
                  ></Box>
                </Flex>

                <h1 className="logo">
                  <a href="/">Corona Graph</a>
                </h1>

                <div className="card-wrapper">
                  <AnimatedCard
                    onClick={() => onClickAnimateCard1()}
                    variant="cards.primary"
                    style={{
                      transform: x.interpolate((x) => `scale(${x})`),
                      color: color,
                      backgroundColor: backgroundColor,
                    }}
                  >
                    <Box>
                      <Box sx={{ display: 'flex' }}>
                        <p className="tit">{t('cases')}</p>
                      </Box>
                      <div className="case-count">
                        <div className="add-co">
                          <p>{prettyPrintStat(countryInfo.todayCases)}</p>
                        </div>
                        <div className="all-cnt">
                          <strong>
                            {Number(countryInfo.cases).toLocaleString()}
                          </strong>
                          {isKorea && (
                            <p className="kor-num">
                              {numberToKorean(countryInfo.cases)}
                            </p>
                          )}
                        </div>
                      </div>
                    </Box>
                  </AnimatedCard>

                  <AnimatedCard
                    onClick={() => onClickAnimateCard2()}
                    sx={{ marginLeft: 4 }}
                    variant="cards.primary"
                    style={{
                      transform: x2.interpolate((x2) => `scale(${x2})`),
                      color: color2,
                      backgroundColor: backgroundColor2,
                    }}
                  >
                    <Box>
                      <Box sx={{ display: 'flex' }}>
                        <p className="tit">{t('recover')}</p>
                      </Box>

                      <div className="case-count">
                        <div className="add-co">
                          <p>{prettyPrintStat(countryInfo.todayRecovered)}</p>
                        </div>
                        <div className="all-cnt">
                          <strong>
                            {Number(countryInfo.recovered).toLocaleString()}
                          </strong>
                          {isKorea && (
                            <p className="kor-num">
                              {numberToKorean(countryInfo.recovered)}
                            </p>
                          )}
                        </div>
                      </div>
                    </Box>
                  </AnimatedCard>

                  <AnimatedCard
                    onClick={() => onClickAnimateCard3()}
                    sx={{ marginLeft: 4 }}
                    variant="cards.primary"
                    style={{
                      transform: x3.interpolate((x3) => `scale(${x3})`),
                      color: color3,
                      backgroundColor: backgroundColor3,
                    }}
                  >
                    <Box>
                      <Box sx={{ display: 'flex' }}>
                        <p className="tit">{t('death')}</p>
                      </Box>

                      <div className="case-count">
                        <div className="add-co">
                          <p>{prettyPrintStat(countryInfo.todayDeaths)}</p>
                        </div>
                        <div className="all-cnt">
                          <strong>
                            {Number(countryInfo.deaths).toLocaleString()}
                          </strong>
                          {isKorea && (
                            <p className="kor-num">
                              {numberToKorean(countryInfo.deaths)}
                            </p>
                          )}
                        </div>
                      </div>
                    </Box>
                  </AnimatedCard>
                </div>
              </div>

              <Box
                sx={{
                  marginTop: 4,
                }}
              >
                <WorldMap
                  selectCountry={country}
                  countries={mapCountries}
                  vaccine={vaccine}
                  center={mapCenter}
                  zoom={mapZoom}
                  casesType={casesType}
                />
              </Box>
            </Box>

            {/* 타임라인 오브젝트 엔트리~~ */}
            {/* <section className="vaccine-wrap">
              {vaccine?.map((data, i) => {
                return (
                  <div className="country">
                    <strong>
                      <span>{i + 1}</span>
                      {data.country}
                    </strong>
                    <p className="latest">
                      <em>{data.latest.toLocaleString()}</em>
                      <span>{numberToKorean(data.latest)}</span>
                    </p>
                    <div className="timeline">
                      {Object.entries(data.timeline)
                        .reverse()
                        .map((obj) => (
                          <p>
                            {obj[0]} {obj[1].toLocaleString()}
                          </p>
                        ))}
                    </div>
                  </div>
                );
              })}
            </section> */}

            <ListContainer>
              <section className="search-wrap">
                <h3>
                  {/* {country} */}
                  {/* undefined 일경우 기본값 설정 */}
                  {countryInfo.country ?? 'world wide'}
                  {/* {console.log(countryInfo.country)} */}
                  {korList.length &&
                  korList.filter((data, i) => data.value === country)[0] ? (
                    <span>
                      {
                        korList.filter((data, i) => data.value === country)[0]
                          .krname
                      }
                    </span>
                  ) : (
                    <span>{isKorea ? '전 세계' : 'World'}</span>
                  )}
                </h3>
                <div className="ipt-wrap">
                  <input
                    placeholder={'search'}
                    onChange={(e) => handleInput(e.target.value)}
                    value={iptVal}
                    type="text"
                  />
                  {iptVal.length ? (
                    <button onClick={handleDelete} aria-label="delete">
                      <TiDelete />
                    </button>
                  ) : null}
                </div>
                <button
                  className="btn-world"
                  onClick={() => onCountrySelect('WorldWide')}
                >
                  <BiWorld /> {isKorea ? '전세계' : 'World data'}
                </button>
                <ul>
                  {filterListAfter.map((cnt, i) => {
                    return (
                      <li key={i}>
                        <button onClick={() => onCountrySelect(cnt.value)}>
                          {/* {cnt.value} |{` `} */}
                          {cnt.name} |{` `}
                          {cnt.krname}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </section>
              <StateDesc
                country={countryInfo.country}
                countryInfo={countryInfo}
                dataContinent={dataContinent}
              />

              <section className="data-history">
                <article className="col-box2">
                  <Suspense fallback={<div>...loading</div>}>
                    <HistoryChart historyData={historyData} />
                  </Suspense>
                  {/* 배너 1 */}
                  <div className="ad-area">
                    <ins
                      class="kakao_ad_area"
                      style={{ display: 'none' }}
                      data-ad-unit="DAN-mwqFSM6E3eNIieiE"
                      data-ad-width="320"
                      data-ad-height="100"
                    ></ins>
                    <script
                      type="text/javascript"
                      src="//t1.daumcdn.net/kas/static/ba.min.js"
                      async
                    ></script>
                  </div>
                </article>
              </section>

              <Suspense fallback={<div>...loading</div>}>
                <CovidPieNationChart covidData={dataContinent} isKo={false} />
              </Suspense>
              <Suspense fallback={<div>...loading</div>}>
                <List
                  isKorea={isKorea}
                  korList={korList}
                  mapCountries={mapCountries}
                  vaccine={vaccine}
                  vaccineData={vaccineData}
                />
              </Suspense>
              <Suspense fallback={<div>...loading</div>}>
                <Content />
              </Suspense>

              <div className="btn-wrap">
                <SaveImage countryInfo={countryInfo} />
                <ShareKakao />
              </div>
              {/* <div className="ad-area">
                <ins
                  class="kakao_ad_area"
                  style={{ display: 'none' }}
                  data-ad-unit="DAN-ZBmnkAjXXHwGmtjV"
                  data-ad-width="320"
                  data-ad-height="100"
                ></ins>
                <script
                  type="text/javascript"
                  src="//t1.daumcdn.net/kas/static/ba.min.js"
                  async
                ></script>
              </div> */}
            </ListContainer>
          </ThemeProvider>
        </Route>
      </Switch>
    </div>
  );
}

export default App;
