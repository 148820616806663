import React, { useEffect, useState } from 'react';
import axios from 'axios';
import cheerio from 'cheerio';
import './index.scss';

// https://www.yna.co.kr/theme/headlines-history
const CheerCmpNewsCovid = () => {
  const getHtml = async () => {
    try {
      return await axios.get('/theme/headlines-history');
    } catch (error) {
      console.error(error);
    }
  };

  const [mydata, setMydata] = useState('');
  const [mydata2, setMydata2] = useState('');

  useEffect(() => {
    getHtml()
      .then((html) => {
        let ulList = [];
        const $ = cheerio.load(html.data);
        // console.log('a1111', html);
        const $bodyList = $('.list-type039 ul').children('li');
        $bodyList.each(function (i, elem) {
          ulList[i] = {
            title: $(this).find('strong.tit-news').text(),
            url: $(this).find('.news-con a').attr('href'),
            image_url: $(this).find('.img-con img').attr('src'),
            image_alt: $(this).find('p.poto a img').attr('alt'),
            summary: $(this).find('p.lead').text().slice(0, -11),
            date: $(this).find('.txt-time').text(),
          };
        });

        /** 여러문자열 중 하나라도 포함되어있는지 찾기 */
        const conditions = [
          '코로나',
          '확진',
          '바이러스',
          '백신',
          '감염',
          '지원금',
        ];

        const data = ulList.filter((n) =>
          conditions.some(
            (el) => n.title.includes(el) || n.summary.includes(el)
          )
        );

        return data;
      })
      .then((res) => {
        // console.log('aaa', res);
        setMydata(res);
      });
  }, []);

  return (
    <div className="news-wrap">
      <h3>
        Korea News <span>{mydata.length}</span>
      </h3>
      {mydata &&
        mydata.map((data, i) => {
          return (
            <div key={i} className="news-list">
              <a href={data.url} target="_blank">
                <div className="imgbox">
                  <img src={data.image_url} alt="" />
                </div>
                <div>
                  <strong>{data.title}</strong>
                  {data.summary && <p>{data.summary}</p>}
                  <p className="date">{data.date}</p>
                </div>
              </a>
            </div>
          );
        })}
    </div>
  );
};

export default CheerCmpNewsCovid;
