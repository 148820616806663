/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import React from 'react';
import { Map, TileLayer } from 'react-leaflet';

//Relative path imports
import { ShowDataOnMap } from '../../helpers/index';
import './index.scss';

const WorldMap = ({
  countries,
  selectCountry,
  vaccine,
  casesType,
  center,
  zoom,
}) => {
  // 맵 언어 설정
  const mapUrl =
    'https://tile.jawg.io/2a1380ac-361c-457c-806d-2c8ebcda05e7/{z}/{x}/{y}.png?access-token=7ThZBgOXHUFwy346Gozs1GXywI0G9BGpGSO60m2XStEnPf76gr0HFoInYe8nSh72';

  return (
    <React.Fragment>
      <div className="map-box">
        <Box
          sx={{
            height: '100%',
            '.leaflet-container': {
              height: '100%',
            },
          }}
        >
          <Map center={center} zoom={zoom} maxZoom={5} minZoom={1}>
            <TileLayer
              attribution='Made by <a href="https://maxime.io">Maxime.io</a> for educational purpose only - Data source <a href="https://github.com/CSSEGISandData/COVID-19">CSSE</a> &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors '
              url={mapUrl}
            />

            {ShowDataOnMap(countries, selectCountry, vaccine, casesType)}
          </Map>
        </Box>
      </div>
    </React.Fragment>
  );
};

export default WorldMap;
