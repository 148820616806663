import React, { useEffect, useState } from 'react';

const ContSymp = ({ isKo }) => {
  return (
    <>
      {/* 증상 */}
      {isKo ? (
        <article className="col-box1">
          <h3>
            <span>COVID-19의 주요 증상</span>
          </h3>
          <p>
            COVID-19의 가장 흔한 증상은 발열 마른 기침 피로입니다. 덜 흔하고
            일부 환자에게 영향을 미칠 수있는 다른 증상은 다음과 같습니다.
          </p>
          <ul className="list-type1">
            <li> 미각 또는 후각 상실</li>
            <li> 코 막힘</li>
            <li> 결막염 (적목 현상이라고도 함) 인후염</li>
            <li> 두통</li>
            <li> 근육 또는 관절</li>
            <li> 고통</li>
            <li> 다양한 유형의 피부 발진</li>
            <li> 메스꺼움 또는 구토</li>
            <li> 설사,</li>
            <li> 오한 또는 현기증.</li>
          </ul>
          <p>중증 COVID‐19 질병의 증상은 다음과 같습니다.</p>
          <ul className="list-type1">
            <li>호흡 곤란</li>
            <li>식욕 부진</li>
            <li>착란</li>
            <li>지속적인 통증 또는</li>
            <li>가슴의 압력</li>
            <li>고온 (38 ° C 이상).</li>
          </ul>
          <p>기타 덜 흔한 증상</p>
          <ul className="list-type1">
            <li> 과민성</li>
            <li> 착란</li>
            <li> 줄인</li>
            <li> 의식 (때때로 발작과 관련됨)</li>
            <li> 걱정</li>
            <li> 우울증</li>
            <li> 수면 장애</li>
            <li>
              {' '}
              뇌졸중 뇌 염증 섬망 및 신경 손상과 같은 더 심각하고 드문 신경 학적
              합병증.
            </li>
          </ul>
          <p>
            호흡 곤란 또는 숨가쁨과 관련된 발열 및 또는 기침을 경험하는 모든
            연령의 사람들은 가슴 통증이나 압박감, 언어 상실 또는 움직임 상실과
            관련된 즉시 치료를 받아야합니다. 가능하면 의료 제공자 핫라인이나
            의료 시설에 먼저 전화하여 올바른 클리닉으로 연결 되도록 하세요.
          </p>
        </article>
      ) : (
        <article className="col-box1">
          <h3>
            <span>What are the symptoms of COVID-19?</span>
          </h3>
          <p>
            The most common symptoms of COVID-19 are Fever Dry cough Fatigue
            Other symptoms that are less common and may affect some patients
            include
          </p>
          <ul className="list-type1">
            <li>Loss of taste or smell</li>
            <li>Nasal congestion</li>
            <li>Conjunctivitis (also known as red eyes) Sore throat</li>
            <li>Headache</li>
            <li>Muscle or joint</li>
            <li>pain</li>
            <li>Different types of skin rash</li>
            <li>Nausea or vomiting</li>
            <li>Diarrhea,</li>
            <li>Chills or dizziness. </li>
          </ul>
          <p>Symptoms of severe COVID‐19 disease include:</p>
          <ul className="list-type1">
            <li>Shortness of breath</li>
            <li>Loss of appetite</li>
            <li>Confusion</li>
            <li>Persistent pain or</li>
            <li>pressure in the chest</li>
            <li>High temperature (above 38 °C). </li>
          </ul>
          <p>Other less common symptoms are</p>
          <ul className="list-type1">
            <li>Irritability </li>
            <li>Confusion </li>
            <li>Reduced</li>
            <li>consciousness(sometimes associated with seizures) </li>
            <li>Anxiety </li>
            <li>Depression</li>
            <li>Sleep disorders </li>
            <li>
              More severe and rare neurological complications such as strokes
              brain inflammation delirium and nerve damage.{' '}
            </li>
          </ul>
          <p>
            People of all ages who experience fever and/or cough associated with
            difficulty breathing or shortness of breath chest pain or pressure,
            or loss of speech or movement should seek medical care immediately.
            If possible call your health care provider hotline or health
            facility first, so you can be directed to the right clinic.
          </p>
        </article>
      )}
    </>
  );
};

export default ContSymp;
