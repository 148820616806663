import React, { useEffect, useState } from 'react';

const ContInfo = ({ isKo }) => {
  return (
    <>
      {/* 정보 */}
      {isKo ? (
        <article>
          <h3>
            <span>코로나 바이러스 감염증 -19 (COVID-19) 정보</span>
          </h3>

          <div>
            <dl>
              <dt>정의</dt>
              <dd>SARS-CoV-2 감염으로 인한 호흡기 증후군</dd>
            </dl>
            <dl>
              <dt>질병 분류</dt>
              <dd>
                <ul>
                  <li>합법적 감염증 : 1 급 감염증 신 감염증 증후군</li>
                  <li> 질병 코드 : U07.1</li>
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>병원체</dt>
              <dd>SARS-CoV-2 : Coronaviridae에 속하는 RNA 바이러스</dd>
            </dl>
            <dl>
              <dt>전파 경로</dt>
              <dd>
                <p>현재까지는 비말(침방울), 접촉을 통한 전파로 알려짐</p>
                <ul>
                  <li>
                    기침이나 재채기를 할 때 생긴 비말(침방울)을 통한 전파 등
                  </li>
                  <li>
                    코로나19 바이러스에 오염된 물건을 만진 뒤 눈, 코, 입을
                    만지는 경우
                  </li>
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>잠복 기간</dt>
              <dd>1 ~ 14 일 (평균 4 ~ 7 일)</dd>
            </dl>
            <dl>
              <dt>진단 기준</dt>
              <dd>
                <ul>
                  <li>
                    환자 : 진단을 위한 검사기준에 따라 감염병병원체 감염이
                    확인된 사람
                  </li>
                  <li>
                    진단을 위한 검사기준
                    <ul>
                      <li>검체에서 바이러스 분리</li>
                      <li>검체에서 특정 유전자 검출</li>
                    </ul>
                  </li>
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>징후</dt>
              <dd>
                발열, 권태감, 기침, 호흡곤란 및 폐렴 등 경증에서 중증까지 다양한
                호흡기감염증이 나타남 그 외 가래, 인후통, 두통, 객혈과 오심,
                설사 등도 나타남
              </dd>
            </dl>
            <dl>
              <dt>치료법</dt>
              <dd>
                <ul>
                  <li>대증 치료 : 수액 보충, 해열제 등 보존적 치료</li>
                  <li>특이적인 항바이러스제 없음</li>
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>치사</dt>
              <dd>
                <ul>
                  <li>
                    전세계 치명률은 약 3.4%(WHO, 3.5 기준) 단, 국가별 · 연령별
                    치명률 수준은 매우 상이함
                  </li>

                  <li>
                    고령, 면역기능이 저하된 환자, 기저질환을 가진 환자가 주로
                    중증, 사망 초래
                  </li>
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>조치</dt>
              <dd>
                <p>환자 관리</p>
                <ul>
                  <li>표준주의, 비말, 접촉주의 준수</li>
                  <li>
                    증상이 있는 동안 가급적 집에서 휴식을 취하고 다른 사람과
                    접촉을 피하도록 권고
                  </li>
                </ul>
              </dd>
              <dd>
                <p>접촉자 관리</p>
                <ul>
                  <li>감염증상 발생 여부 관찰</li>
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>예방</dt>
              <dd>
                <ul>
                  <li>
                    <p>올바른 손씻기</p>
                    <ul>
                      <li>흐르는 물에 비누로 30초 이상 꼼꼼하게 손씻기</li>
                      <li>
                        특히, 외출 후, 배변 후, 식사 전·후, 기저귀 교체 전·후,
                        코를 풀거나 기침, 재채기 후 등에는 반드시 실시
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>기침 에티켓 준수</p>
                    <ul>
                      <li>
                        기침할 때는 휴지나 옷소매 위쪽으로 입과 코를 가리고 하기
                      </li>
                      <li>호흡기 증상이 있는 경우 마스크 착용</li>
                    </ul>
                  </li>
                  <li>씻지 않은 손으로 눈, 코, 입 만지지 않기</li>
                  <li>주위 환경을 자주 소독하고 환기하기</li>
                </ul>
              </dd>
            </dl>
          </div>
        </article>
      ) : (
        <article>
          <h3>
            <span>
              Coronavirus Infectious Disease-19 (COVID-19) information
            </span>
          </h3>

          <div>
            <dl>
              <dt>Justice</dt>
              <dd>Respiratory syndrome caused by SARS-CoV-2 infection</dd>
            </dl>
            <dl>
              <dt>Classification of diseases</dt>
              <dd>
                <ul>
                  <li>
                    Legal infectious disease: first-degree infectious disease
                    new infectious disease syndrome
                  </li>
                  <li>Disease Code: U07.1</li>
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>Pathogen</dt>
              <dd>SARS-CoV-2: RNA virus belonging to Coronaviridae</dd>
            </dl>
            <dl>
              <dt>Propagation path</dt>
              <dd>
                <p>
                  Until now, known as droplets (saliva), radio waves through
                  contact
                </p>
                <ul>
                  <li>
                    Propagation through droplets (saliva) generated when
                    coughing or sneezing, etc.
                  </li>
                  <li>
                    Touching an object contaminated with the Corona 19 virus and
                    then touching your eyes, nose, or mouth
                  </li>
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>Incubation period </dt>
              <dd>1-14 days (average 4-7 days)</dd>
            </dl>
            <dl>
              <dt>Diagnostic criteria</dt>
              <dd>
                <ul>
                  <li>
                    Patient: A person who has been confirmed to be infected with
                    an infectious disease pathogen according to the examination
                    criteria for diagnosis
                  </li>
                  <li>
                    Test criteria for diagnosis
                    <ul>
                      <li>Virus isolation from sample</li>
                      <li>Detection of specific genes in samples</li>
                    </ul>
                  </li>
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>Symptom</dt>
              <dd>
                Various respiratory infections from mild to severe, such as
                fever, boredom, cough, shortness of breath and pneumonia, etc.
                In addition, phlegm, sore throat, headache, hemoptysis, nausea,
                and diarrhea appear
              </dd>
            </dl>
            <dl>
              <dt>cure</dt>
              <dd>
                <ul>
                  <li>
                    Symptomatic treatment: conservative treatment such as fluid
                    supplementation and antipyretic drugs
                  </li>
                  <li>No specific antiviral agents</li>
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>Lethality</dt>
              <dd>
                <ul>
                  <li>
                    The world fatality rate is ContInfo 3.4% (based on WHO,
                    3.5), but the level of fatality rate by country and age is
                    very different.
                  </li>

                  <li>
                    The elderly, patients with reduced immune function, and
                    patients with underlying diseases are mainly severe and
                    cause death.
                  </li>
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>management</dt>
              <dd>
                <p>Patient care</p>
                <ul>
                  <li>Compliance with standardsism, splashism, contactism</li>
                  <li>
                    Recommend to rest at home and avoid contact with others
                    while symptoms are present
                  </li>
                </ul>
              </dd>
              <dd>
                <p>Contact management</p>
                <ul>
                  <li>Observation of infection symptoms</li>
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>prevention</dt>
              <dd>
                <ul>
                  <li>
                    <p>Proper hand washing</p>
                    <ul>
                      <li>
                        Wash your hands thoroughly with soap under running water
                        for at least 30 seconds
                      </li>
                      <li>
                        Especially, after going out, after defecation, before
                        and after meals, before and after diaper change, after
                        blowing your nose, coughing, or sneezing, etc.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>Observe cough etiquette</p>
                    <ul>
                      <li>
                        When coughing, cover your mouth and nose with a tissue
                        or upper sleeve
                      </li>
                      <li>Wear a mask if you have respiratory symptoms</li>
                    </ul>
                  </li>
                  <li>
                    Avoid touching your eyes, nose or mouth with unwashed hands
                  </li>
                  <li>
                    Disinfect and ventilate the surrounding environment
                    frequently
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
        </article>
      )}
    </>
  );
};

export default ContInfo;
