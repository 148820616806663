import React from 'react';
import { Circle, Popup } from 'react-leaflet';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import '../i18n';

import './index.scss';
/** 숫자 대비 어느정도 값이 있어야 원형이 보임 */
const casesTypeColors = {
  cases: {
    hex: '#ddbf17',
    multiplier: 600,
  },
  recovered: {
    hex: '#019ccb',
    multiplier: 600,
  },
  deaths: {
    hex: '#cf4218',
    multiplier: 1200,
  },
  vaccine: {
    hex: '#18cf64',
    multiplier: 1200,
  },
  select: {
    hex: '#d6450c',
    multiplier: 600,
  },
};

export const ShowDataOnMap = (
  data,
  selectCountry,
  vaccine,
  casesType = 'cases'
) => {
  // console.log(selectCountry, 'select');
  const { t, i18n } = useTranslation();

  return data.map((country, index) => {
    const isSelect = country.countryInfo.iso2 === selectCountry;
    // console.log(isSelect);
    return (
      <Circle
        center={[country.countryInfo.lat, country.countryInfo.long]}
        // fillOpacity={0.1}
        fillOpacity={
          isSelect
            ? 0.6
            : (0.11 *
                Math.sqrt(country[casesType]) *
                casesTypeColors[casesType].multiplier) /
              500000
        }
        color={
          isSelect
            ? casesTypeColors['select'].hex
            : casesTypeColors[casesType].hex
        }
        fillColor={
          isSelect
            ? casesTypeColors['select'].hex
            : casesTypeColors[casesType].hex
        }
        weight={1.2}
        radius={
          Math.sqrt(country[casesType]) * casesTypeColors[casesType].multiplier
        }
        key={index}
      >
        <Popup className="custom-popup">
          <div className="pop-head">
            <div className="flag">
              <img src={`${country.countryInfo.flag}`} alt="Flag" />
              <h3>{country.country}</h3>
            </div>
          </div>
          <div className="case-info">
            <p className="info-desc">
              {t('cases')}
              <strong>
                {numeral(country.cases).format('0,0')}
                {country.todayCases > 0 && (
                  <span>+{numeral(country.todayCases).format('0,0')}</span>
                )}
              </strong>
            </p>

            <p className="info-desc">
              {t('recover')}

              <strong>
                {numeral(country.recovered).format('0,0')}
                {country.todayRecovered > 0 && (
                  <span>+{numeral(country.todayRecovered).format('0,0')}</span>
                )}
              </strong>
            </p>

            <p className="info-desc">
              {t('death')}

              <strong>
                {numeral(country.deaths).format('0,0')}
                {country.todayDeaths > 0 && (
                  <span>+{numeral(country.todayDeaths).format('0,0')}</span>
                )}
              </strong>
            </p>
          </div>

          <div className="list-line">
            <p className="info-desc">
              <span> {t('rateFatal')}</span>
              <strong>
                {(
                  (Number(country.deaths) / Number(country.cases)) *
                  100
                ).toFixed(2)}
                %
              </strong>
            </p>

            <p className="info-desc">
              <span> {t('rateRecover')} </span>
              <strong>
                {(
                  (Number(country.recovered) / Number(country.cases)) *
                  100
                ).toFixed(2)}
                %
              </strong>
            </p>
            <p className="info-desc">
              <span>
                {' '}
                {t('per1m')} {t('cases')}
              </span>
              <strong>
                {Number(country.casesPerOneMillion).toLocaleString()}
              </strong>
            </p>
            <p className="info-desc">
              <span>
                {' '}
                {t('per1m')} {t('death')}
              </span>
              <strong>
                {Number(country.deathsPerOneMillion).toLocaleString()}
              </strong>
            </p>
          </div>
        </Popup>
      </Circle>
    );
  });
};

export const prettyPrintStat = (stat) => {
  // return stat ? `+${numeral(stat).format('0,0a')}` : '+0';
  return stat ? `+${Number(stat).toLocaleString()}` : '+0';
};
