import React, { useEffect, useState } from 'react';
import { CgScrollH } from 'react-icons/cg';

const ContDistance = ({ isKo }) => {
  return (
    <>
      {/* 거리두기 */}
      {isKo ? (
        <>
          <article>
            <h3>
              <span>사회적 거리두기</span>
            </h3>
            <p>
              사회적 거리두기 또는 물리적 거리두기 혹은 안전한 거리두기는 감염
              관리의 종류 중 하나이다. 사회적 거리두기의 목표는 감염이 걸린
              사람과 감염되지 않은 사람 사이의 접촉 가능성을 감소시켜 질병의
              전파를 늦추고 궁극적으로 사망률을 최소화 하는 것이다.
            </p>
            <p>
              또한 사회적 거리두기는 개인 또는 집단 간 접촉을 최소화하여
              감염병의 전파를 감소시키는 공중보건학적 감염병 통제 전략이다.
              개인과 개인의 접촉을 최소화하는 규모부터 고위험군과 저위험군
              인구집단을 분리시키는 방법까지 다양한 층위의 방식이 존재한다.
            </p>
            <p>
              사회적 거리두기는 기침이나 재채기 등의 비말 감염의 경우에 가장
              효과적이다. 성적 접촉을 포함한 직접적인 신체적 접촉에 의한
              감염이나 간접적인 물리적 접촉에 의한 감염이나 공기 감염하는
              경우에도 효과적이다.
            </p>
            <p>
              참고로, 세계보건기구(WHO)는 사회적으로는 연결되어 있지만
              물리적으로만 거리를 두는 것을 강조하는 의미로 '사회적
              거리두기'라는 표현 대신 '물리적 거리두기'라는 표현을 권장하고
              있다.
            </p>
          </article>

          <article>
            <h4>
              거리 두기 단계별 기준 및 방역 조치
              <span className="ico-scroll">
                <CgScrollH />
              </span>
            </h4>
            <div className="table-scroll-wrap">
              <table>
                <colgroup>
                  <col style={{ width: '100px' }} />
                  <col style={{ width: 'auto' }} span="*" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col" rowspan="2">
                      구분
                    </th>
                    <th scope="col">1단계</th>
                    <th scope="col">1.5단계</th>
                    <th scope="col">2단계</th>
                    <th scope="col">2.5단계</th>
                    <th scope="col">3단계</th>
                  </tr>
                  <tr>
                    <th scope="col">생활방역</th>
                    <th scope="col" colspan="2">
                      지역적 유행 단계
                    </th>
                    <th scope="col" colspan="2">
                      전국적 유행 단계
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>개념</td>
                    <td>생활 속 거리 두기</td>
                    <td>지역적 유행 개시</td>
                    <td>지역 유행 급속 전파, 전국적 확산 개시</td>
                    <td>전국적 유행 본격화</td>
                    <td>전국적 대유행</td>
                  </tr>
                  <tr>
                    <td>상황</td>
                    <td>
                      통상적인 방역 및 의료체계의 감당 가능한 범위 내에서 유행
                      통제 중
                    </td>
                    <td>
                      특정 권역에서 의료체계의 통상 대응 범위를 위협하는
                      수준으로 1주 이상 유행 지속
                    </td>
                    <td>
                      1.5단계 조치 후에도 지속적 유행 증가 양상을 보이며, 유행이
                      전국적으로 확산되는 조짐 관찰{' '}
                    </td>
                    <td>
                      의료체계의 통상 대응 범위를 초과하는 수준으로 전국적
                      유행이 1주 이상 지속 또는 확대
                    </td>
                    <td>
                      전국적으로 급격하게 환자가 증가하여 의료체계 붕괴 위험에
                      직면{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>기준</td>
                    <td>
                      <p>- 주 평균 일일 국내 발생 확진자 수</p>
                      <p>
                        {' '}
                        · 수도권 100명,충청·호남·경북·경남권 30명, 강원·제주
                        10명 미만
                      </p>
                    </td>
                    <td>
                      <p>- 주 평균 일일 국내 발생 확진자 수</p>
                      <p>
                        · 수도권 100명,충청·호남·경북·경남권 30명, 강원·제주
                        10명 이상
                      </p>
                      <p> - 60대 이상 주 평균 일일 확진자 수 </p>
                      <p>
                        {' '}
                        · 수도권 40명, 충청·호남·경북·경남권 10명, 강원·제주 4명
                        이상
                      </p>
                    </td>
                    <td>
                      <p>다음과 같은 세 가지 중 하나 충족</p>

                      <ul>
                        <li>
                          ① 유행권역에서 1.5단계 조치 1주 경과 후, 확진자수가
                          1.5단계 기준의 2배 이상 지속
                        </li>
                        <li>
                          ② 2개 이상 권역에서 1.5단계 유행이 1주 이상 지속
                        </li>
                        <li>③ 전국 확진자 수 300명 초과 상황 1주 이상 지속</li>
                      </ul>
                    </td>
                    <td>
                      <p>
                        - 전국 주평균 확진자400명~500명 이상이거나, 전국 2단계
                        상황에서 더블링 등 급격한 환자 증가 상황
                      </p>
                      <p>
                        {' '}
                        ※격상시 60대 이상 신규확진자 비율, 중증환자 병상수용능력
                        등 중요하게 고려
                      </p>
                    </td>
                    <td>
                      <p>
                        - 전국 주평균 확진자 800~1000명 이상이거나, 2.5단계
                        상황에서 더블링 등 급격한 환자 증가
                      </p>
                      <p>
                        ※격상시 60대 이상 신규확진자 비율, 중증환자 병상수용능력
                        등 중요하게 고려
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>핵심 메시지</td>
                    <td>
                      일상생활과 사회경제적 활동을 유지하면서, 코로나19 예방을
                      위해 방역수칙 준수{' '}
                    </td>
                    <td>지역유행 시작, 위험지역은 철저한 생활방역</td>
                    <td>
                      지역유행 본격화, 위험지역은 불필요한 외출과 모임 자제,
                      사람이 많이 모이는 다중이용시설 이용 자제
                    </td>
                    <td>
                      전국 유행 확산, 가급적 집에 머무르며 외출·모임과
                      다중이용시설 이용을 최대한 자제
                    </td>
                    <td>
                      전국적 대유행, 원칙적으로 집에 머무르며 다른 사람과 접촉
                      최소화
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </article>

          <article>
            <h4>
              1.다중이용시설 주요 방역조치
              <span className="ico-scroll">
                <CgScrollH />
              </span>
            </h4>
            <div className="table-scroll-wrap">
              <table>
                <colgroup>
                  <col style={{ width: '100px' }} />
                  <col style={{ width: 'auto' }} span="*" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col" rowspan="2">
                      구분
                    </th>
                    <th scope="col">1단계</th>
                    <th scope="col">1.5단계</th>
                    <th scope="col">2단계</th>
                    <th scope="col">2.5단계</th>
                    <th scope="col">3단계</th>
                  </tr>
                  <tr>
                    <th scope="col">생활방역</th>
                    <th scope="col" colspan="2">
                      지역적 유행 단계
                    </th>
                    <th scope="col" colspan="2">
                      전국적 유행 단계
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan="2">중점관리시설</td>
                    <td rowspan="2">이용인원 제한 등 핵심방역수칙 의무화</td>
                    <td rowspan="2">
                      이용인원 제한 강화, 위험도 높은 활동 금지
                    </td>
                    <td>유흥시설 5종 집합금지</td>
                    <td rowspan="2">
                      방문판매 등 직접판매 홍보관, 노래연습장, 실내
                      스탠딩공연장, 집합금지{' '}
                    </td>
                    <td rowspan="4">
                      필수시설 외 집합금지 이외 시설도 운영 제한
                    </td>
                  </tr>
                  <tr>
                    <td>
                      이외 시설은 21시 이후 운영 중단 등 제한 강화, 위반 시
                      원스트라이크아웃제
                    </td>
                  </tr>
                  <tr>
                    <td>일반관리시설</td>
                    <td>정상 운영 기본 방역수칙 3가지 의무화</td>
                    <td>시설별 특성에 따라 이용인원 제한</td>
                    <td>이용인원 제한 강화, 위험도 높은 활동 금지</td>
                    <td>
                      21시 이후 운영 중단 등 제한 강화, 위반 시
                      원스트라이크아웃제
                    </td>
                  </tr>
                  <tr>
                    <td>기타시설</td>
                    <td colspan="2">정상 운영</td>
                    <td>마스크 착용 의무화</td>
                    <td>이용인원 제한</td>
                  </tr>
                  <tr>
                    <td>국공립시설</td>
                    <td>경륜·경마 등 50% 인원 제한</td>
                    <td>경륜·경마 등 20%, 이외 시설 50% 인원 제한</td>
                    <td>경륜·경마 등 중단 이외 시설 30% 인원 제한 </td>
                    <td>
                      체육시설, 경륜·경마 등 운영 중단 이외 시설 30% 인원 제한
                    </td>
                    <td>실내·외 구분 없이 운영 중단</td>
                  </tr>
                  <tr>
                    <td>사회복지시설 (어린이집 포함)</td>
                    <td colspan="4">
                      철저한 방역 하에 운영
                      <p>
                        * 유행 지역의 감염 확산 양상, 시설별 위험도·방역관리
                        상황 등 고려하여 필요 시 일부 시설은 휴관하고 긴급돌봄
                        등 필수 서비스만 제공
                      </p>
                    </td>
                    <td>휴관·휴원 권고 긴급돌봄 등 유지 </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </article>

          <article>
            <h4>
              2. 일상 및 사회·경제적 활동 방역조치
              <span className="ico-scroll">
                <CgScrollH />
              </span>
            </h4>
            <div className="table-scroll-wrap">
              <table>
                <colgroup>
                  <col style={{ width: '100px' }} />
                  <col style={{ width: 'auto' }} span="*" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col" rowspan="2">
                      구분
                    </th>
                    <th scope="col">1단계</th>
                    <th scope="col">1.5단계</th>
                    <th scope="col">2단계</th>
                    <th scope="col">2.5단계</th>
                    <th scope="col">3단계</th>
                  </tr>
                  <tr>
                    <th scope="col">생활방역</th>
                    <th scope="col" colspan="2">
                      지역적 유행 단계
                    </th>
                    <th scope="col" colspan="2">
                      전국적 유행 단계
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>마스크 착용 의무화</td>
                    <td>
                      중점·일반관리시설, 대중교통, 의료기관, 약국, 요양시설,
                      주야간보호시설, 집회·시위장, 실내 스포츠 경기장, 고위험
                      사업장 등{' '}
                    </td>
                    <td>1단계에 실외 스포츠 경기장 추가 </td>
                    <td>실내 전체, 위험도 높은 실외 활동</td>
                    <td colspan="2">
                      실내 전체, 2m 이상 거리 유지가 어려운 실외{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>모임·행사</td>
                    <td>
                      500명 이상 행사는 지자체 신고·협의 필요, 방역수칙 의무화
                    </td>
                    <td>
                      1단계 조치 유지, 축제 등 일부 행사는 100인 이상 금지{' '}
                    </td>
                    <td>100인 이상 금지</td>
                    <td>50인 이상 금지 </td>
                    <td>10인 이상 금지</td>
                  </tr>
                  <tr>
                    <td>스포츠 관람</td>
                    <td>관중 입장(50%)</td>
                    <td>관중 입장(30%)</td>
                    <td>관중 입장(10%)</td>
                    <td>무관중 경기</td>
                    <td>경기 중단</td>
                  </tr>
                  <tr>
                    <td>교통시설 이용</td>
                    <td colspan="2">마스크 착용 의무화</td>
                    <td>
                      교통수단(차량) 내 음식 섭취 금지 추가 (국제항공편 제외)
                    </td>
                    <td>
                      KTX, 고속버스 등 50% 이내로 예매 제한 권고 (항공기 제외){' '}
                    </td>
                    <td>KTX, 고속버스 등 50% 이내로 예매 제한 (항공기 제외)</td>
                  </tr>
                  <tr>
                    <td>등교</td>
                    <td>밀집도 2/3 원칙, 조정 가능 </td>
                    <td>밀집도 2/3 준수 </td>
                    <td>
                      밀집도 1/3 원칙 (고등학교 2/3) 최대 2/3 내에서 운영 가능
                    </td>
                    <td>밀집도 1/3 준수 </td>
                    <td>원격수업 전환 </td>
                  </tr>
                  <tr>
                    <td rowspan="2">종교활동</td>
                    <td colspan="5">
                      ※ 단계 조정 시 방역 및 집단감염 상황에 따라 종교계와
                      협의하여 구체적 조치 내용 및 대상 결정
                    </td>
                  </tr>
                  <tr>
                    <td>
                      좌석 한 칸 띄우기 모임·식사 자제 권고 (숙박행사 금지)
                    </td>
                    <td>
                      정규예배 등 좌석 수 의 30% 이내로 제한 모임·식사 금지{' '}
                    </td>
                    <td>
                      정규예배 등 좌석 수 의 20% 이내로 제한 모임·식사 금지{' '}
                    </td>
                    <td>비대면, 20명 이내로 인원 제한 모임·식사 금지 </td>
                    <td>1인 영상만 허용 모임·식사 금지 </td>
                  </tr>
                  <tr>
                    <td rowspan="2">직장근무</td>
                    <td>
                      기관·부서별 적정 비율 재택근무 등 실시 권고 (예: 1/5 수준)
                    </td>
                    <td colspan="2">
                      기관·부서별 재택근무 등 확대 권고 (예: 1/3 수준)
                    </td>
                    <td>인원의 1/3 이상 재택근무 등 권고 </td>
                    <td>필수인력 이외 재택근무 등 의무화</td>
                  </tr>
                  <tr>
                    <td>고위험사업장 마스크 착용 의무화</td>
                    <td colspan="4">
                      고위험사업장 마스크 착용, 환기·소독, 근로자 간 거리 두기
                      등 의무화
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </article>
        </>
      ) : (
        <>
          <article>
            <h3>
              <span>Social Distancing</span>
            </h3>
            <p>
              Social distancing, also called “physical distancing,” means
              keeping a safe space between yourself and other people who are not
              from your household.
            </p>
            <p>
              To practice social or physical distancing, stay at least 6 feet
              (about 2 arms’ length) from other people who are not from your
              household in both indoor and outdoor spaces.
            </p>
            <p>
              Social distancing should be practiced in combination with other
              everyday preventive actions to reduce the spread of COVID-19,
              including wearing masks, avoiding touching your face with unwashed
              hands, and frequently washing your hands with soap and water for
              at least 20 seconds.
            </p>
          </article>
        </>
      )}
    </>
  );
};

export default ContDistance;
